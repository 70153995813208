<template>
  <div class="news-page">
    <div>
      <!-- f1左侧banner开始 -->
      <div class="banner">
        <div class="block">
          <el-carousel trigger="click" height="374px" indicator-position="none">
            <el-carousel-item
              v-for="item in newsPhotos"
              :key="item.id"
              name="item.title"
            >
              <div class="pic_item">
                <a @click=" handleOpen(item.fromlink)"  target="_blank">
                  <img class="small" :src="item.thumb" />
                  <h3>{{ item.title }}</h3>
                </a>
              </div>

              <!-- <img src="../assets/images/banner_01.jpg" alt=""> -->
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- f1左侧banner结束 -->
      <!-- f1右侧News开始 -->
      <div class="news bordershadow" v-if="technologyNews.length">
        <!-- 类目标题开始 -->
        <div class="titleRow">
          <div class="title">{{ $t("News") }}</div>
          <div class="more">
            <!-- <router-link to="/">
              More
            </router-link> -->
          </div>
        </div>
        <!-- 类目标题结束 -->
        <ul class="newsUlContainer">
          <li v-for="(item, index) of technologyNews" :key="index">
            <a @click=" handleOpen(item.fromlink)"
             target="_blank">
              <div class="newsItem">
                <!-- News栏目每条新闻左侧开始 -->
                <div>
                  <p class="p2row">
                    {{ item.title }}
                  </p>
                  <p class="dateTime">{{ item.date | formatDateTime }}</p>
                </div>
                <!-- News栏目每条新闻左侧结束 -->
                <!-- News栏目每条新闻右侧图片开始 -->
                <div class="newsItem-img" v-show="item.thumb">
                  <img :src="item.thumb" alt="" />
                </div>
              </div>
              <!-- News栏目每条新闻右侧图片结束 -->
            </a>
          </li>
        </ul>
      </div>
      <!-- f1右侧News结束 -->
      <!-- f2左侧Trending News开始 -->
      <div class="trending-news">
        <div class="bordershadow">
          <!-- 类目标题开始 -->
          <div class="titleRow" v-if="years.length">
            <div class="title">{{ $t("Trending_News") }}</div>
            <div class="selectStyle">
              <el-select v-model="selectValue" placeholder="All">
                <el-option
                  v-for="(item, index) of years"
                  :key="index"
                  :label="$t(item.year.trim().replace(/ /g, '_'))"
                  :value="item.year"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- 类目标题结束 -->
          <!-- 左侧Trending News新闻内容开始 -->
          <ul class="newsUlContainer" v-if="latestNews.length">
            <li v-for="(item, index) of latestNews" :key="index">
              <!-- <a :href="item.fromlink" target="_blank"> -->
              <a
                :href="`https://www.ikcest.org/articleS-${item.newsId}.htm`"
                target="_blank"
              >
                <div class="newsItem">
                  <div>
                    <p class="p2row">
                      {{ item.title }}
                    </p>
                    <p class="dateTime">{{ item.date | formatDateTime }}</p>
                  </div>
                  <div class="newsItem-img" v-show="item.thumb">
                    <img :src="item.thumb" alt="" />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <!-- 左侧Trending News新闻内容结束 -->
        </div>
        <!-- 分页开始 current(当前页)-->
        <!--current-change,currentPage改变时会触发 current-page当前页数  page-size每页显示个数  page-count总页数  hide-on-single-page只有一页时是否隐藏-->
        <div v-if="page" class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page.pageNum"
            background
            layout="prev, pager, next"
            :page-count="page.totalPage"
            v-show="page.totalPage > 1"
          >
          </el-pagination>
        </div>
        <!-- 分页结束 -->
      </div>
      <!-- f2左侧Trending News结束 -->

      <!-- f2右侧Latest News开始 -->
      <div class="latest-news bordershadow">
        <!-- 类目标题开始 -->
        <div class="titleRow">
          <div class="title">{{ $t("Latest_News") }}</div>
          <div class="more">
            <!-- <router-link to="/"> -->
            <!-- More -->
            <!-- </router-link> -->
          </div>
        </div>
        <!-- 类目标题结束 -->
        <ul class="newsUlContainer" v-if="unescoNews.length">
          <li v-for="(item, index) of unescoNews" :key="index">
            <a
              :href="`https://www.ikcest.org/articleS-${item.newsId}.htm`"
              target="_blank"
            >
              <div class="newsItemNoImg">
                <p class="p2row">
                  {{ item.title }}
                </p>
                <p class="dateTime">{{ item.date | formatDateTime }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- f2右侧Latest News结束 -->
    </div>
    <el-dialog
      v-if="dialogVisible"
      title="提示"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <ALink :aUrl="linkUrl" @close="handleClose"></ALink>
    </el-dialog>
  </div>
</template>

<script>
export default {
    components: {
    ALink: () => import("@/components/ALink"),
  },
  data() {
    return {
      dialogVisible: false,
      linkUrl: "22222",
      selectValue: "All",
      latestNews: [], //页面的Trending News
      newsPhotos: [], //banner
      technologyNews: [], // 页面News
      unescoNews: [], //页面Latest News
      years: [],
      page: null,

      // 轮播图
      banners: [],
      // 轮播图
    };
  },

  mounted() {
    this.getData();
  },

  watch: {
    selectValue() {
      this.getYear();
    },
  },
  methods: {
    handleOpen(value) {
      this.dialogVisible = true;
      this.linkUrl = value;
    },
    handleClose() {
      this.dialogVisible = false;
    },

    getData() {
      // http://192.168.4.193:82/data/c2cNews.json?action=news&name=news&alias=list
      // let url = "/data/api/coronavirus.do?action=index";
      let url = "/data/api/c2cNews.json?action=action&name=news&alias=list";
      this.axios.get(url).then((res) => {
        let {
          latestNews,
          newsPhotos,
          page,
          technologyNews,
          unescoNews,
          years,
        } = res.data.messages.data.news;
        this.latestNews = latestNews;
        this.newsPhotos = newsPhotos.slice(0, 6);
        this.technologyNews = technologyNews.slice(0, 3);
        this.unescoNews = unescoNews.slice(0, 5);
        this.page = page;
        years.unshift({ year: "All" });
        this.years = years;
        // console.log(res,"11this.technologyNews")
      });

      // console.log(pageNum);
    },

    handleCurrentChange(newPage) {
      this.page.pageNum = newPage;
      this.getPagination();
      // console.log(newPage)
    },

    getYear() {
      let selectYear = this.selectValue;
      // console.log(this.selectValue,"thisselectValue")
      if (this.selectValue == "All") {
        selectYear = "";
      }
      let url =
        "/data/api/c2cNews.json?action=news&name=news&year=" + selectYear;
      this.axios.get(url).then((res) => {
        this.latestNews = res.data.messages.data.contents;
        this.page = res.data.messages.data.page;
        // console.log(this.latestNews,"22页面的Trending News");
        // console.log(this.page);
      });
    },

    getPagination() {
      let selectYear = this.selectValue;
      if (this.selectValue == "All" || this.selectValue == "全部") {
        selectYear = "";
      }

      let url =
        "/data/api/c2cNews.json?action=news&name=news&year=" +
        selectYear +
        "&pageSize=10&pageNum=" +
        this.page.pageNum +
        "&alias=list";
      this.axios.get(url).then((res) => {
        // console.log(res,"1111111Pagination11111111")
        this.latestNews = res.data.messages.data.contents;
        // console.log(this.latestNews,"33页面的Trending News");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog{
  height: 430px !important;
}
.news-page {
  padding-top: 110px;
  width: 100%;
  min-width: 1200px;
  background-color: #f7f8f8;
  color: #444546;
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.banner {
  width: 725px;
  height: 374px;
  margin-right: 15px;
  margin-bottom: 15px;
  img {
    width: 725px;
    height: 374px;
  }
}

.itemImg {
  height: 600px;
}

.news {
  width: 460px;
  height: 374px;
  margin-bottom: 15px;
}

// f2左侧Trending News开始
.trending-news {
  width: 725px;
  margin-right: 15px;
  margin-bottom: 30px;
  // border: 1px solid red;
}

.selectStyle {
  width: 80px;
  margin-top: -2px;
  ::v-deep .el-input__inner {
    height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
}

.latest-news {
  width: 460px;
  height: 584px;
  margin-bottom: 30px;
}

// banner图样式开始
.el-carousel__item h3 {
  color: white;
  font-size: 15px;
  opacity: 0.85;
  margin: 0;
  width: 725px;
  height: 30px;
  background-color: rgb(31, 30, 30);
  line-height: 30px;
  text-align: center;
}

.block {
  ::v-deep button {
    color: white !important;
    background-color: #009fe3 !important;
  }

  ::v-deep .el-icon-arrow-right {
    color: white !important;
    font-size: 21px;
  }
  ::v-deep .el-icon-arrow-left {
    color: white !important;
    font-size: 21px;
  }
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.block {
  width: 725px;
  height: 374px;
}
.small {
  width: 100%;
  height: 100%;
  position: relative;
}

.pic_item {
  position: relative;
  height: 100%;
}
.pic_item:hover {
  cursor: pointer;
}

.pic_item img {
  width: 100%;
  height: 100%;
}

.pic_item h3 {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

// banner图样式结束

//可共用样式 驼峰

// 新闻内容
.newsUlContainer {
  li + li {
    border-top: 1px solid #dde0e1;
  }
}

.newsItem {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  .newsItem-img > img {
    width: 120px;
    height: 88px;
  }
}

.newsItemNoImg {
  padding: 10px 0px;
}

// 标题行
.titleRow {
  height: 30px;
  line-height: 30px;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  .title {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      line-height: 30px;
      border-radius: 50%;
      border: 3px solid #009fe3;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
  .more {
    cursor: pointer;
    a {
      color: #009fe3;
    }
  }
}

// 日期样式
.dateTime {
  font-size: 12px;
  font-weight: 400;
  color: #98999a;
  padding: 10px 0px;
}

// 2行文字
.p2row {
  width: 100%;
  height: 51px;
  padding-right: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

// 分页
.pagination {
  margin-top: 20px;
  ::v-deep .el-pagination.is-background .btn-prev {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .btn-next {
    background-color: white;
  }
  // 修改页数默认背景颜色
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff; //修改选中项背景
  }
}
</style>
